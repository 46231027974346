import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, NavLink, Row } from 'reactstrap';
import './App.css'

const Plans = () => {
    const [plan, setPlan] = useState(true);
    const toggle = () => setPlan(!plan);
    return (
        <React.Fragment>
            <section className="section bg-light plans" id="plans">

                <div className="bg-overlay bg-overlay-pattern"></div>
                <Container >
                    <Row className="justify-content-center">
                        <Col lg={10}>
                            <div className="text-center mb-5">
                                <h3 className="mb-3" style={{color: '#212529'}}>Choose the plan that's right for you</h3>
                                <p className="" style={{ fontSize: 18, fontFamily:'Montserrat'}}>Give a man a fish and you feed him for a day. Teach him how to fish and you feed him for a lifetime. </p>

                            </div>
                        </Col>
                    </Row>

                    <Row className="gy-4" style={{color: '#212529',}}>
                        <Col lg={6} >
                            <Card className="plan-box mb-0" style={{ width: '600px' }} >
                                <CardBody style={{ height: 450,width:'100%' }} className="m-2">
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <h5 className="mb-1  fw-semibold mt-1" style={{textTransform:'uppercase',color: '#000'}} >Crypto trading essentials</h5>
                                            {/* <p className="text-muted mb-0">For Startup</p> */}
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <h2 className="month" style={{color: '#212529'}} ><sup><small>USDT</small></sup><span className="ff-secondary fw-bold">100</span> </h2>
                                    </div>

                                    <div className='mt-4'>
                                        <ul className="list-unstyled text-muted vstack gap-3 ff-secondary">
                                            {/* <p style={{ textAlign: 'left', hyphens: 'auto', lineHeight: 1.5 }}><span style={{ color: '#fff' }}>AIM :</span> Provide essential knowledge and skills to learners for trading cryptocurrencies effectively.</p> */}
                                            <li>
                                                <div className="d-flex mt-3">
                                                    <div className="flex-shrink-0 text-primary me-1">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>
                                                    <div className=" mb-3">
                                                        <b style={{color: '#212529'}}> Introduction to Crypto world</b>
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="flex-shrink-0 text-primary me-1">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>

                                                    <div className=" mb-3">
                                                        <b style={{color: '#212529'}}>Hands-On Trading (CEX and DEX)</b>
                                                    </div>

                                                </div>
                                                <div className="d-flex mt-3 ">
                                                    <div className="flex-shrink-0 text-primary me-1">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>

                                                    <div className=" mb-3">
                                                        <b style={{color: '#212529'}}>Understanding Cold storage</b>
                                                    </div>

                                                </div>
                                                <div className="d-flex mt-3 ">
                                                    <div className="flex-shrink-0 text-primary me-1">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>

                                                    <div className=" mb-4">
                                                        <b style={{color: '#212529'}}>Learn to create your Portfolio</b>
                                                    </div>

                                                </div>
                                                <div className="d-flex mt-2 mb-3">
                                                    <div className="flex-shrink-0 text-primary me-1">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>
                                                    <div >
                                                        <b style={{color: '#212529'}}>Expert guidance</b>
                                                    </div>

                                                </div>
                                            </li>
                                        </ul>
                                        <div className='start' style={{marginTop:12}}>
                                            <Link to="/start/1" style={{color:'#fff'}} className="btn btn-primary w-100 ">Get
                                                Started</Link>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col lg={6}>
                            <Card className="plan-box mb-0 ribbon-box right" style={{ width: '600px' }}>

                                <CardBody style={{ height: 450,width:'100%' }} className="m-2">
                                <div className="ribbon-two ribbon-two-danger"><span>Popular</span></div>
                                    <div className="d-flex align-items-center">
                                        <div className="">
                                            <h5 className="mb-3" style={{color: '#000',textTransform:'uppercase',lineHeight:1.5}} >Technical analysis: Price Action</h5>
                                            {/* <p className="text-muted mb-0">For Startup</p> */}
                                        </div>

                                    </div>
                                    <div className="">
                                        <h2 className="month" style={{color: '#212529'}}><sup><small>USDT</small></sup><span className="ff-secondary fw-bold">500</span> </h2>
                                    </div>

                                    <div className='mt-4'>
                                        <ul className="list-unstyled text-muted vstack gap-3 ff-secondary">
                                            {/* <p style={{ textAlign: 'left', hyphens: 'auto', lineHeight: 1.5 }}><span style={{ color: '#fff' }}>AIM :</span> Provide essential knowledge and skills to learners for trading cryptocurrencies effectively.</p> */}
                                            <li>
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0 text-primary me-1 mt-2">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>
                                                    <div className="mt-2 mb-3">
                                                        <b style={{color: '#212529'}}>Boost profits with advanced technical analysis strategies.</b>
                                                    </div>
                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="flex-shrink-0 text-primary me-1">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>

                                                    <div className="mb-3">
                                                        <b style={{color: '#212529'}}>Minimize losses and make informed trading decisions.</b>
                                                    </div>

                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="flex-shrink-0 text-primary me-1">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>

                                                    <div className="mb-3">
                                                        <b style={{color: '#212529'}}>Identify trends, patterns, and key indicators.</b>
                                                    </div>

                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="flex-shrink-0 text-primary me-1">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>

                                                    <div className="mb-3">
                                                        <b style={{color: '#212529'}}>Understand market psychology and crypto asset behavior</b>
                                                    </div>

                                                </div>
                                                <div className="d-flex mt-3">
                                                    <div className="flex-shrink-0 text-primary me-1">
                                                        <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                    </div>
                                                    <div className="mb-2">
                                                        <b style={{color: '#212529'}}>Learn to trade confidently and profitably as an independent trader.</b>
                                                    </div>

                                                </div>
                                            </li>
                                        </ul>
                                        <div className="mt-1">
                                            <Link to="/start/2" style={{color:'#fff'}} className="btn btn-primary w-100 ">Get
                                                Started</Link>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                        
                        
                        <Col lg={2}>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default Plans;