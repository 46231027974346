import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory, Link, useParams } from 'react-router-dom';
import Navbar from './navbar';
import FakeNavbar from './FakeNavbar';
import AllFiles from './AllFiles';
import GetStarted from './GetStarted';
import './assets/scss/themes.scss';
import './App.css';

const App = () => {
  
  document.title = "Landing | SAFI BHAI WEBSITE";
  return (
    <React.Fragment>
      {/* <Navbar /> */}
      <Switch className="layout-wrapper landing">
        <Route exact path="/" component={AllFiles} />
        <Route path="/start/:srh" component={GetStarted} />

      </Switch>

    </React.Fragment>
  );
};

export default App;
