import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Row, ButtonGroup, Button, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { collection, addDoc } from 'firebase/firestore'
import { db } from './config'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [number, setNumber] = useState();
    const [selectedOption, setSelectedOption] = useState('Select Course');
    // const data = firebase
    const handleDropdownSelect = (option) => {
        setSelectedOption(option);
        // setDropdownOpen(false);
    };

    const Submit = async () => {
        if (!name || !email || !message || !number || selectedOption == 'Select Course') {
            return alert("All field's are required")
        }
        setName("")
        setEmail("")
        setMessage("")
        setNumber()
        setSelectedOption("Select Course")
        toast.info("We've received your message!");

        // const docRef = await addDoc(collection(db,'contactData'),{
        //     name,
        //     email,
        //     course:selectedOption,
        //     message
        // })

    };

    return (
        <React.Fragment>
            <section className="section" id="contact">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={8}>
                            <div className="text-center mb-5">
                                <h3 className="mb-3 fw-semibold" style={{color: '#000',fontFamily:'Montserrat'}}>Get In Touch</h3>
                                {/* <p className="text-muted mb-4 ff-secondary">We thrive when coming up with innovative ideas but also
                                    understand that a smart concept should be supported with faucibus sapien odio measurable
                                    results.</p> */}
                            </div>
                        </Col>
                    </Row>

                    <Row className="gy-4 mx-auto">

                        <Col lg={12}>
                            <div>
                                <Form>
                                    <Row>
                                        <Col lg={6}>
                                            <div className="mb-4">
                                                <label htmlFor="name" className="form-label fs-13">Name</label>
                                                <input name="name" id="name" type="text"
                                                    className="form-control bg-light border-light" placeholder="Your name*"
                                                    value={name} onChange={(e) => setName(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-4">
                                                <label htmlFor="email" className="form-label fs-13">Email</label>
                                                <input name="email" id="email" type="email"
                                                    className="form-control bg-light border-light" placeholder="Your email*"
                                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>

                                            <ButtonGroup className="mb-3 mt-3">
                                                <UncontrolledButtonDropdown>
                                                    <Button color="light" onClick={(e) => e.preventDefault()}> {selectedOption} </Button>
                                                    <DropdownToggle tag="button" onClick={(e) => e.preventDefault()} className="btn btn-light" split>
                                                    </DropdownToggle>
                                                    <DropdownMenu >
                                                        <DropdownItem onClick={() => handleDropdownSelect('Crypto Trading Essentials')}>Crypto Trading Essentials</DropdownItem>
                                                        <DropdownItem onClick={() => handleDropdownSelect('Technical Analysis')}>Technical Analysis</DropdownItem>
                                                    </DropdownMenu>
                                                </UncontrolledButtonDropdown>
                                            </ButtonGroup>

                                        </Col>
                                        <Col lg={6}>
                                            <div className="mb-4">
                                                <label htmlFor="email" className="form-label fs-13">Contact Number</label>
                                                <input name="number" id="number" type="number"
                                                    className="form-control bg-light border-light" placeholder="Your number*"
                                                    value={number} onChange={(e) => setNumber(e.target.value)}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="mb-3">
                                                <label htmlFor="comments" className="form-label fs-13">Message</label>
                                                <textarea name="comments" id="comments" rows="3"
                                                    className="form-control bg-light border-light"
                                                    placeholder="Your message..."
                                                    value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className="text-end">
                                            <input type="button" onClick={() => Submit()} className="submitBnt btn btn-primary"
                                                value="Send Message" />
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    <ToastContainer
                        className="toast-position"
                        position="bottom-right"
                        autoClose={10000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        icon={false}
                        // theme="primary"
                    />

                </Container>
            </section>
        </React.Fragment>
    );
};

export default Contact;