import React, { useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom'
import { Col, Container, Row, NavLink } from 'reactstrap';
import FakeNavbar from './FakeNavbar';

import bitcoin from './assets/images/btc.jpeg'
import bitcoin1 from './assets/images/bitcoins.jpeg'
import bitcoin2 from './assets/images/eleventh.jpeg'
import third from './assets/images/third.jpeg'
import fourth from './assets/images/fourth.jpeg'
import trading from './assets/images/trading.jpeg'
import fund from './assets/images/fund1.jpg'
import fund1 from './assets/images/fund2.jpg'
import seventh from './assets/images/seventh.jpeg'
import eirth from './assets/images/eirth.jpeg'
import backgroundimage from './assets/images/CryptoTrading.png'
import './started.css'; // Import your component-specific CSS file
import { animateScroll as scroll, scroller } from 'react-scroll';
import Card from './Card';

const GetStarted = () => {
    const { srh } = useParams()

    const whatsappNumber = '8688198601'; // Replace with the desired WhatsApp number

    const handleClick = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const whatsappUrl = isMobile
            ? `https://api.whatsapp.com/send?phone=${whatsappNumber}`
            : `https://web.whatsapp.com/send?phone=${whatsappNumber}`;

        window.open(whatsappUrl, '_blank');
    };

    const styles = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: 15,
        '@media (min-width: 768px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',

        },
        '@media (min-width: 480px)': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
        },
    };

    useLayoutEffect(() => {
        window.scrollTo(0, 0);

    }, [])
    return (
        <React.Fragment >
            <FakeNavbar />
            <div className="bg-overlay bg-overlay-pattern"></div>
            <div style={{
                textAlign: 'center',
                marginTop: 30
                /* Add any additional styling properties */
            }}>


                {/* <hr /> */}
                <Container >
                    <Row className="justify-content-center">
                        <Col lg={12}>
                            <div style={{ textAlign: 'center', marginTop: 10 }}>
                                {srh == 1 ? <Card
                                    title="Crypto Trading Essentials"
                                    img1={bitcoin}
                                    img2={bitcoin1}
                                    img3={bitcoin2}
                                    line1="This course covers the basics of cryptocurrencies, including crypto language, buying your first crypto, trading on exchanges, securing your crypto, and understanding basics of technical analysis. You will gain a solid foundation in the world of cryptocurrencies and develop the skills and knowledge necessary to navigate the market with confidence."
                                    line2="Provide essential knowledge and skills to learners for trading cryptocurrencies effectively."
                                    line3="1. Knowledge of key crypto concepts: Participants will gain an understanding of key crypto concepts such as blockchain, wallets, exchanges, and market trends."
                                    line4="2. Familiarity with trading platforms: Participants will become familiar with popular trading platforms used to buy and sell cryptocurrencies.                                    "
                                    line5="4. Understanding of risk management: Participants will learn about the importance of risk management in crypto trading and develop basic risk management strategies, including the use of trading tools such as stop-loss (SL), trailing SL, take-profit (TP), and one-cancels-the-other (OCO) orders."
                                    line6="5. Technical analysis basics: Participants will gain an introduction to technical analysis concepts, such as chart reading, trend lines, and support and resistance levels."
                                    line7="3. Fundamental analysis basics: Participants will gain an introduction to fundamental analysis concepts, such as market news and events that can affect crypto prices."
                                    line8="6. Building a trading plan: Participants will learn how to develop a basic trading plan, including setting goals, identifying entry and exit points, and managing risks using trading tools such as SL, trailing SL, TP, and OCO orders."
                                    line9="7. Practical trading experience: Participants will have the opportunity to gain practical trading experience by executing trades using a demo trading account and practicing the concepts learned in the course."
                                /> : null}
                                {srh == 2 ?
                                    <Card
                                        title="TECHNICAL ANALYSIS"
                                        img1={third}
                                        img2={fourth}
                                        line1="It is a method of analyzing the price movements of cryptocurrencies by examining historical data to identify patterns and trends using statistical tools and charting. It involves the use of indicators and oscillators to identify potential buy or sell opportunities, and support and resistance levels to find entry or exit points in the market."
                                        line2="The aim of this course is to provide learners with a comprehensive understanding of the tools and techniques used to analyze the price movements of cryptocurrencies. They will gain a strong comprehension of charting, technical "
                                        line3="1. Understand the principles of technical analysis and how they apply to cryptocurrencies."
                                        line4="2. Be able to read and interpret different types of charts used in technical analysis, including line charts, bar charts, and candlestick charts"
                                        line5="4. Know how to use various technical indicators to identify potential buy or sell opportunity in the market"
                                        line6="5. Understand the concept of support and resistance levels and be able to identify key levels on price charts."
                                        line7="3. Know how to use technical analysis in combination with other forms of analysis, such as fundamental analysis, to make informed trading decisions."
                                    />
                                    : null}
                                {/* {srh == 3 ? <div style={{ justifyContent: 'center', marginTop: 40 }}> */}

                                    {/* Fundamental analysis */}
                                    {/* <Card
                                        title="FUNDAMENTAL ANALYSIS"
                                        img1={seventh}
                                        img2={fund}
                                        img3={fund1}
                                        line1="Fundamental analysis is an important tool for evaluating the intrinsic value of a cryptocurrency. By analyzing various economic and financial factors, traders and investors can make informed decisions about when to buy, hold, or sell a particular cryptocurrency."
                                        line2="The aim of this course is to equip learners with the knowledge and skills to analyze the underlying economic and financial factors that influence the value of cryptocurrencies. Participants will learn how to assess the macroeconomic environment, evaluate market trends, and analyze company financials to make informed investment decisions. Through case studies and practical exercises, learners will develop a deep understanding of the fundamental analysis process and its application in the crypto market. By the end of the course, participants will be able to identify undervalued or overvalued assets and make sound investment decisions."
                                        line3="1. Develop an understanding of the economic and financial factors that drive the value of cryptocurrencies."
                                        line4="2. Learn how to identify the key drivers of value for different cryptocurrencies"
                                        line5="4. Learn how to perform a fundamental analysis of cryptocurrencies, including evaluating the technology behind the cryptocurrency, its adoption rate, and its competition."
                                        line6="5. Understand the impact of news events and market sentiment on the value of cryptocurrencies"
                                        line7="3. Develop the ability to make informed investment decisions based on a comprehensive analysis of the cryptocurrency market"
                                        line8="6. Learn to evaluate the long-term potential of different cryptocurrencies, including their ability to disrupt traditional financial systems"
                                        line9="7. Understand how to use fundamental analysis in conjunction with other forms of analysis, such as technical analysis, to gain a comprehensive understanding of the market."
                                        line10="In conclusion, However, it is important to remember that cryptocurrency markets are highly volatile and unpredictable, and traders should always use caution and conduct thorough research before making any investment decisions."
                                    /> */}

                                {/* </div> : null} */}
                                {/* {srh == 4 ? <div style={{ justifyContent: 'center', marginTop: 40 }}>

                                    <div className="container1">
                                        <div className="text2">
                                            <h2 style={{ textAlign: 'center' }}>Risk management</h2>
                                        </div>
                                        <div className="image2">
                                            <center>
                                                <img src={eirth} alt="bitcoins" height="180" width="300" style={{ borderRadius: '10%' }} />
                                            </center>
                                        </div>
                                    </div>

                                    <h5 className="mt-3" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto' }}><span style={{ fontWeight: 'bold', fontSize: 20 }}>Aim: </span>The aim of this course is to provide traders with the necessary knowledge and skills to identify potential risks associated with trading cryptocurrencies, assess their impact and develop strategies to minimize them. The course aims to help traders manage risks effectively, make more informed trading decisions, and improve their overall trading performance and profitability.</h5>
                                    <span style={{ fontWeight: 'bold', fontSize: 23 }} className="mt-3">Outcomes: </span>
                                    <h5 className="mt-3" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto' }}>1. Understanding the different types of risks associated with trading cryptocurrencies and how to identify them. </h5>
                                    <h5 className="mt-3" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto' }}>2. Assessing the impact of each risk identified and calculating the potential losses that may arise. </h5>
                                    <h5 className="mt-3" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto' }}>3. Developing effective risk management strategies to minimize risks associated with trading cryptocurrencies. </h5>
                                    <h5 className="mt-3" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto' }}>4. Monitoring risks associated with trading cryptocurrencies on an ongoing basis and adjusting strategies accordingly.</h5>
                                    <h5 className="mt-3" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto' }}>5. Understanding trading psychology, risk tolerance, and portfolio management.</h5>
                                    <h5 className="mt-3" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto' }}>By the end of the course, traders should have a deep understanding of risk management concepts and be able to apply this knowledge to manage risks effectively when trading cryptocurrencies. They should also be able to develop and implement risk management strategies that can help them make more informed trading decisions and improve their overall trading performance.</h5>

                                    <div className="mb-5" onClick={handleClick}>
                                        <NavLink className="btn btn-soft-success w-50">Enroll Me</NavLink>
                                    </div>

                                </div> : null} */}
                                {/* {srh == 5 ? <div style={{ justifyContent: 'center', }}>
                                    <div style={{ textAlign: 'center', marginTop: 30 }}>
                                        <div style={{ justifyContent: 'center', }}>
                                            <div className="container1">
                                                <div className="text1">
                                                    <h2 style={{ color: '#000', fontFamily: 'Montserrat' }}>Portfolio Management</h2>
                                                </div>
                                            </div>
                                            <h5 className="txt1" style={{ lineHeight: 1.8, textAlign: 'justify', hyphens: 'auto', color: '#000', fontFamily: 'Montserrat' }}>Portfolio management involves a disciplined approach to managing investments in cryptocurrencies, with a focus on maximizing returns while minimizing risk through strategic asset allocation and risk management. Our portfolio management service is designed for individuals who are looking for a professional to manage their cryptocurrency investments. As an experienced crypto trader, our manager will provide personalized investment strategies tailored to your risk tolerance, financial goals, and investment horizon.</h5>
                                            <h5 className="txt1" style={{ lineHeight: 1.8, textAlign: 'justify', hyphens: 'auto', color: '#000', fontFamily: 'Montserrat' }}>Our portfolio management service covers all types of trading styles, including day trading, swing trading, and long-term holding. We monitor the market 24/7 and make informed decisions on when to buy or sell assets, with a focus on minimizing risk and maximizing returns.</h5>
                                            <h5 className="txt1" style={{ lineHeight: 1.8, textAlign: 'justify', hyphens: 'auto', color: '#000', fontFamily: 'Montserrat' }}>By using our portfolio management service, you can benefit from our expertise and save time that would otherwise be spent researching and managing your portfolio. Our aim is to provide a hassle-free experience, allowing you to relax knowing that your investments are being professionally managed.</h5>
                                            <center>
                                                <div className="mb-3 mt-5" onClick={handleClick}>
                                                    <NavLink className="btn btn-soft-success" style={{ width: 259 }}>CONTACT TO KNOW MORE </NavLink>
                                                </div>
                                            </center>
                                        </div>
                                    </div>

                                </div> : null} */}
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>

        </React.Fragment >
    )
}

export default GetStarted