import React from "react";
import Home from './home';
import Plans from './plans';
import Faqs from './faq';
import Reviews from './reviews';
import Contact from './contact';
import About from './About';
import Footer from './footer';
import Navbar from './navbar';

const AllFiles = () => {
    return (
        <div>
            <Navbar />
            <Home />
            <Plans />
            <About />
            <Faqs />
            <Reviews />
            <Contact />
            <Footer />
        </div>
    )
}

export default AllFiles