import React, { useEffect, useLayoutEffect } from 'react';
import { Col, Container, Row, NavLink } from 'reactstrap';
import './started.css'; // Import your component-specific CSS file

const Card = (props) => {
    const whatsappNumber = '8688198601'; // Replace with the desired WhatsApp number

    const handleClick = () => {
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
        const whatsappUrl = isMobile
            ? `https://api.whatsapp.com/send?phone=${whatsappNumber}`
            : `https://web.whatsapp.com/send?phone=${whatsappNumber}`;

        window.open(whatsappUrl, '_blank');
    };

    return (
        <React.Fragment >
            <div style={{ textAlign: 'center', marginTop: 20 }}>
                <div style={{ justifyContent: 'center', marginTop: 50 }}>
                    <div className="container1">
                        <div className="text1">
                            <h2 style={{ color: '#000',fontFamily:'Montserrat' }}>{props.title}</h2>
                        </div>
                    </div>
                    <h5 className="txt1" style={{ lineHeight: 1.8, textAlign: 'justify', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}>{props.line1}</h5>
                    <div style={{display:'flex',justifyContent: 'center',marginTop:'10px'}}>
                        <h5 className="txt mt-2" style={{ lineHeight: 1.8, textAlign: 'justify', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}><span className='txt' style={{ fontWeight: 'bold' }}>Aim: </span>{props.line2}</h5>
                    </div>

                    <Container>
                        <Row>
                            <Col lg={8} md={8}>
                                <h5 className="txt1" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}>{props.line3}</h5>
                                <h5 className="mt-3 txt" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}>{props.line4}</h5>
                                <h5 className="mt-3 txt" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'left', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}>{props.line7}</h5>

                            </Col>
                            <Col lg={4} md={4} className="d-flex justify-content-center align-items-center mb-3">
                                <img src={props.img1} className='img-fluid' />
                            </Col>
                            <span style={{ fontWeight: 'bold', fontSize: 23 }} className="mt-2 mb-3">Outcomes: </span>

                            <Col lg={4} md={4} className="d-flex justify-content-center align-items-center ">
                                <img src={props.img2} className='img-fluid' />
                            </Col>

                            <Col lg={8} md={8} xs="12" sm={10} >
                                <h5 className="mt-3 txt" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}>{props.line5}</h5>
                                <h5 className="mt-3 txt mb-3" style={{ lineHeight: 1.8, textAlign: 'justify', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}>{props.line6}</h5>

                            </Col>
                            <Col lg={8} md={8}>
                                <h5 className="mt-3 txt" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}>{props.line8}</h5>
                                <h5 className="mt-3 txt" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'left', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}>{props.line9}</h5>

                            </Col>
                            <Col lg={4} md={4} className="d-flex justify-content-center align-items-center">
                                <img src={props.img3} className='img-fluid' />
                            </Col>
                            <h5 className="mt-3" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'left', hyphens: 'auto', color: '#000',fontFamily:'Montserrat' }}>{props.line10}</h5>
                            {/* <h5 className="mt-3" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'justify', hyphens: 'auto',color:'#000' }}></h5> */}
                            <div className="mb-5" onClick={handleClick}>
                                <NavLink className="btn btn-success w-75"><b style={{color:'#fff'}}>Enroll Me</b></NavLink>
                            </div>

                        </Row>
                    </Container>
                </div>
            </div>


        </React.Fragment>
    )
}

export default Card