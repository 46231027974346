import React, { useState, useEffect } from "react";
import { Collapse, Container, NavbarToggler, NavLink } from "reactstrap";
import Scrollspy from "react-scrollspy";
import { scroller } from 'react-scroll';

const CoursePageNavbar = () => {
    const [isOpenMenu, setisOpenMenu] = useState(false);
    const [navClass, setnavClass] = useState("");

    const toggle = () => setisOpenMenu(!isOpenMenu);

    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);
        return () => {
            window.removeEventListener("scroll", scrollNavigation, true);
        };
    }, []);

    const scrollNavigation = () => {
        const scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setnavClass("is-sticky");
        } else {
            setnavClass("");
        }
    };

    const scrollToSection = (sectionId) => {
        scroller.scrollTo(sectionId, {
            duration: 800,
            smooth: 'easeInOutQuart',
        });
        setisOpenMenu(false); // Close the menu when clicking a link
    };

    return (
        <React.Fragment>
            <nav className={"navbar navbar-expand-lg navbar-landing fixed-top " + navClass} id="navbar">
                <Container>
                    <NavLink className="navbar-brand p-1" href="#hero">
                        <i><h6 className="mt-2 card-logo card-logo-dark" >CRYPTO TRADING<br/><span style={{fontSize:10,marginLeft:30}}>ACADEMY</span></h6></i> 
                    </NavLink>

                    <NavbarToggler className="navbar-toggler py-0 fs-20 text-body" onClick={toggle} type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <i className="mdi mdi-menu"></i>
                    </NavbarToggler>

                    <Collapse
                        isOpen={isOpenMenu}
                        className="navbar-collapse"
                        id="navbarSupportedContent"
                    >
                        <Scrollspy
                            offset={-18}
                            items={[
                                "hero",
                                "courses",
                                "about",
                                "reviews",
                                "contact",
                            ]}
                            className="navbar-nav mx-auto mt-2 mt-lg-0"
                            id="navbar-example"
                        >
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="/#hero"
                                    onClick={() => scrollToSection("hero")}
                                >
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="/#plans"
                                    onClick={() => scrollToSection("plans")}
                                >
                                    Courses
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="/#about"
                                    onClick={() => scrollToSection("about")}
                                >
                                    About Us
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="/#reviews"
                                    onClick={() => scrollToSection("reviews")}
                                >
                                    Reviews
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="nav-link"
                                    href="/#contact"
                                    onClick={() => scrollToSection("contact")}
                                >
                                    Contact
                                </a>
                            </li>
                        </Scrollspy>
                    </Collapse>
                </Container>
            </nav>
        </React.Fragment>
    );
};

export default CoursePageNavbar;
