import React from 'react';
import { Col, Container, NavLink, Row, Table } from 'reactstrap';
import { Link } from 'react-router-dom';
import Img from './assets/images/ninth.jpeg'
import Img2 from './assets/images/third.jpeg'
import './started.css'
// Import Swiper styles
const Home = () => {
    return (
        <React.Fragment style={{fontFamily: "Montserrat"}}>
            <section className="section pb-0 hero-section" id="hero">
                <div className="bg-overlay bg-overlay-pattern"></div>
                <Container style={{fontFamily: "Montserrat"}}>
                    <Row className="justify-content-center">
                        <Col lg={12} sm={12} className="mb-">
                            <div className="mt-3 pt-5" style={{ textAlign: 'justify' }}>
                                <h2 className="display-8 fw-semibold mb-3 lh-base" style={{ textAlign: 'center', hyphens: 'auto', fontSize: 27,color:'#000',fontFamily:'Montserrat'}}>Crypto Trading Made<span
                                    className="text-primary" style={{fontFamily:'Montserrat'}}> Easy </span></h2>
                                {/* <h2 className="display-8 fw-semibold mb-3 lh-base" style={{ textAlign: 'center', hyphens: 'auto', fontSize: 27 }}>Unlock Your Potential in Crypto Trading with Our Comprehensive  <span
                                    className="text-primary">Course </span></h2> */}
                                <p className="text mb-0" style={{ lineHeight: 1.8, marginBottom: '16px', textAlign: 'center', hyphens: 'auto', fontSize: 18,fontFamily:'Montserrat'  }}>Unlock Your Potential in Crypto Trading with Our Comprehensive Courses</p>
                            </div>
                        </Col>

                        <section className="section ">
                            <Container>
                                <Row className="align-items-center gy-4">
                                    <Col lg={6} className="order-2 order-lg-1">
                                        <div className="text-muted">
                                            {/* <h5 className="fs-12 text-uppercase text-primary">Design</h5> */}
                                            <h3 className="mb-4" style={{color: '#000',fontFamily:'Montserrat'}}>Why Choose Our Cryptocurrency Trading Course</h3>
                                            {/* <p className="mb-4 ff-secondary">Quality Dashboards (QD) is a condition-specific, actionable web-based application for quality reporting and population
                                                management that is integrated into the Electronic Health Record (EHR).</p> */}

                                            <Row>
                                                <Col sm={6}>
                                                    <div className="vstack gap-2">
                                                        <div className="d-flex align-items-center mb-2">
                                                            <div className="flex-shrink-0 me-2">
                                                                <div className="avatar-xs icon-effect">
                                                                    <div
                                                                        className="avatar-title bg-transparent text-primary rounded-circle h2">
                                                                        <i className="ri-check-fill"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-14 mb-0">Expert Guidance</h5>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-2">
                                                            <div className="flex-shrink-0 me-2">
                                                                <div className="avatar-xs icon-effect">
                                                                    <div
                                                                        className="avatar-title bg-transparent text-primary rounded-circle h2">
                                                                        <i className="ri-check-fill"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-14 mb-0">Comprehensive Cirriculum</h5>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-2">
                                                            <div className="flex-shrink-0 me-2">
                                                                <div className="avatar-xs icon-effect mb-2">
                                                                    <div
                                                                        className="avatar-title bg-transparent text-primary rounded-circle h2 ">
                                                                        <i className="ri-check-fill"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-14 mb-2">One-to-One Training</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col sm={5}>
                                                    <div className="vstack gap-2">
                                                        <div className="d-flex align-items-center mb-2">
                                                            <div className="flex-shrink-0 me-2">
                                                                <div className="avatar-xs icon-effect">
                                                                    <div
                                                                        className="avatar-title bg-transparent text-primary rounded-circle h2">
                                                                        <i className="ri-check-fill"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-14 mb-0">Practical Coaching</h5>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-2">
                                                            <div className="flex-shrink-0 me-2">
                                                                <div className="avatar-xs icon-effect">
                                                                    <div
                                                                        className="avatar-title bg-transparent text-primary rounded-circle h2">
                                                                        <i className="ri-check-fill"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1">
                                                                <h5 className="fs-14 mb-0">Ongoing support</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="mt-4">
                                                <NavLink href="#plans" className="btn btn-primary w-50" style={{ color: "#fff" }}>Learn More <i className="ri-arrow-right-line align-middle ms-1"></i></NavLink>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col lg={6} sm={12} className="col-12 ms-auto order-1 order-lg-2 ">
                                        <div className="container">
                                            <center>
                                                <img src={Img} alt="bitcoin" className="img-home" />
                                            </center>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className="align-items-center mt-5 pt-lg-5 gy-4">
                                    <Col lg={6} sm={12} className="col-12 ms-auto ">
                                        <div className="container">
                                            <center>
                                                <img src={Img2} alt="trading crypto" className="img-home" />
                                            </center>
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <div className="text-muted ps-lg-5">
                                            {/* <h5 className="fs-12 text-uppercase text-primary">structure</h5> */}
                                            <h3 className="mb-4" style={{color: '#000',fontFamily:'Montserrat'}}>Who Can Benefit From This Course?</h3>
                                            {/* <p className="mb-4">used to describe something that is known about or known to be true because there are many documents that describe it,
                                                prove it, etc.</p> */}

                                            <div className="vstack gap-2">

                                                <div className="d-flex align-items-center mb-2">
                                                    <div className="flex-shrink-0 me-2">
                                                        <div className="avatar-xs icon-effect">
                                                            <div
                                                                className="avatar-title bg-transparent text-primary rounded-circle h2">
                                                                <i className="ri-check-fill"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="fs-14 mb-0">Beginners</h5>
                                                    </div>
                                                </div>
                                                
                                                <div className="d-flex align-items-center mb-2">
                                                    <div className="flex-shrink-0 me-2">
                                                        <div className="avatar-xs icon-effect">
                                                            <div
                                                                className="avatar-title bg-transparent text-primary rounded-circle h2">
                                                                <i className="ri-check-fill"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="fs-14 mb-0">Intermediate Traders</h5>
                                                    </div>
                                                </div>
                                                <div className="d-flex align-items-center mb-2">
                                                    <div className="flex-shrink-0 me-2">
                                                        <div className="avatar-xs icon-effect">
                                                            <div
                                                                className="avatar-title bg-transparent text-primary rounded-circle h2">
                                                                <i className="ri-check-fill"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="fs-14 mb-0">Investors</h5>
                                                    </div>
                                                </div>
                                                <div className="mt-4">
                                                    <NavLink href="#plans" style={{ color: "#fff" }} className="btn btn-primary w-50">Learn More <i className="ri-arrow-right-line align-middle ms-1"></i></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>

                            </Container>
                        </section>
                    </Row>
                </Container>

            </section>
        </React.Fragment >
    );
};

export default Home;

